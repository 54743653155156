import { fromPromise } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { errorReporter } from '@straetus/react/components/error-boundary'
import { AuthApiClient } from '@straetus/react/modules/auth'

import { getGlobalGraphQLErrorCode } from './graphql.utils'

// Array of errors we handle gracefully so can be ignored
const skipApiErrorCodes = [
  'api.unknown-combination',
  'api.invalid-two-factor-code',
  'api.validation',
  'api.recourse-not-found',
  'api.to-many-requests',
  'api.transaction-is-connected-to-workflow-step',
  'api.file-is-attachment',
  'api.file-is-used-in-company-settings',
  'api.dossier-invoice-lines-exist',
  'api.payment-arrangement-already-active',
  'api.non-client-user-to-not-user-company',
  'api.payment-commitment-amount-to-high',
  'api.step-already-executed',
  'api.payment-arrangement-not-active',
  'api.user-has-incorrect-status',
  'api.not-allowed-to-modify-dossier',
  'api.dossier-cost.amount-lower-than-processed'
]

const authApi = new AuthApiClient()
let isRefreshing = false
let pendingRequests = []

function resolvePendingRequests() {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

export function createApolloErrorLink(handleReAuthentication?: () => void) {
  return onError(({
    graphQLErrors,
    operation,
    forward
  }) => {
    if (graphQLErrors) {
      const errorCode = getGlobalGraphQLErrorCode(graphQLErrors)

      if (!errorCode || !skipApiErrorCodes.includes(errorCode)) {
        if (handleReAuthentication && graphQLErrors.some(({ message }) => message === 'Unauthorized')) {
          let forward$
          if (!isRefreshing) {
            isRefreshing = true
            forward$ = fromPromise(
              authApi.refreshTokens()
                .then(() => {
                  // Resolve all pending requests
                  resolvePendingRequests()

                  return true
                })
                .catch(() => {
                  // Also clear the pending requests
                  pendingRequests = []
                  handleReAuthentication()
                  return false
                })
                .finally(() => {
                  isRefreshing = false
                })
            )
          } else {
            // Will only emit once the Promise is resolved
            forward$ = fromPromise(
              new Promise((resolve) => {
                pendingRequests.push(resolve)
              })
            )
          }

          return forward$.flatMap(() => {
            return forward(operation)
          })
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.warn('GraphQL error', graphQLErrors)
          }

          // Report the error
          graphQLErrors.forEach((error) => {
            errorReporter.report(error?.['originalError'] || error.message || `Error when doing operation "${operation.operationName}"`, {
              reportLocation: {
                functionName: operation.operationName
              }
            })
          })
        }
      }
    }

    return undefined
  })
}

import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export interface StraetusTextIconProps extends SvgIconProps {
  withSlogan?: boolean
}

export default function StraetusTextIcon({ withSlogan, ...props }: StraetusTextIconProps) {
  return (
    <SvgIcon
      sx={{ width: 120, height: 'auto' }}
      viewBox={`0 0 234 ${withSlogan ? '47' : '30'}`}
      width={"120px"}
      {...props}
    >
      <g
        fill={"none"}
        fillRule={"evenodd"}
        id={"Page-1"}
        stroke={"none"}
        strokeWidth={"1"}
      >
        <g fill={"#FFFFFF"} fillRule={"nonzero"} id={"Straetus_logo_white"}>
          <g id={"Group"}>
            <g>
              <path
                d={"M5.7,28.8 C4.9,28.8 4.2,29.1 3.7,29.8 L0.9,18 L1.1,17.9 C4.6,22.1 9.8,24.4 14.5,24.4 C18,24.4 20.4,23.4 20.4,21.4 C20.4,19.4 17.9,18.7 12.6,17.8 C7.8,16.9 1.7,15.2 1.7,9 C1.7,3.5 7.4,-2.84217094e-14 14.4,-2.84217094e-14 C18.7,-2.84217094e-14 20.7,1.1 22.1,1.1 C22.9,1.1 23.4,0.9 23.9,0.2 L25.9,11 L25.7,11 C23.1,7.5 18.7,5.6 14.3,5.6 C10.7,5.6 8.2,6.8 8.2,8.7 C8.2,10.8 11.5,11.3 14.6,12 C20.4,13 27,14.8 27,21.1 C27,26.6 21.7,30.1 14.9,30.1 C10.3,30.2 7,28.8 5.7,28.8 Z"}
                id={"Path"}
              />
              <path
                d={"M55,8.4 L55,8.4 C52.9,6.7 51,6.2 48.3,6.2 L44.6,6.2 L44.6,26.2 C44.6,28 45.4,29.1 46.8,29.7 L46.8,29.8 L36,29.8 L36,29.7 C37.4,29.1 38.2,28.1 38.2,26.2 L38.2,6.2 L34.5,6.2 C31.7,6.2 29.9,6.7 27.8,8.5 L27.7,8.4 L30,0.5 L52.8,0.5 L55,8.4 Z"}
                id={"Path"}
              />
              <path
                d={"M85.9,29.6 L85.9,29.6 L74.7,29.7 L74.7,29.6 C76,28.9 76.3,27.9 75.5,26.1 L72.6,19.8 L65.1,19.8 L65.1,26.2 C65.1,28 65.9,29.1 67.3,29.7 L67.3,29.8 L56.5,29.8 L56.5,29.7 C57.9,29.1 58.7,28.1 58.7,26.2 L58.7,4 C58.7,2.2 57.9,1.1 56.5,0.5 L56.5,0.4 L73.5,0.4 C79.6,0.4 83.9,4.4 83.9,10 C83.9,13.8 81.9,16.8 78.8,18.4 L82.5,26.3 C83.3,28.1 84.6,29.1 85.9,29.6 Z M65.1,14.1 L72.9,14.1 C75.7,14.1 77.5,12.4 77.5,10.1 C77.5,7.8 75.7,6.1 72.9,6.1 L65.1,6.1 L65.1,14.1 Z"}
                id={"Shape"}
              />
              <path
                d={"M119.6,29.6 L119.6,29.6 L108.5,29.7 L108.5,29.6 C109.9,29 110.3,28.1 109.7,26.3 L108.5,23 L96.6,23 L95.4,26.3 C94.8,28.1 95.2,29 96.6,29.6 L96.6,29.7 L85.5,29.7 L85.5,29.6 C86.9,29 88.1,28.1 88.8,26.3 L97.4,3.8 C98.1,2.1 97.6,1.1 96.2,0.5 L96.2,0.4 L108.9,0.4 L108.9,0.5 C107.5,1.1 107.1,2 107.7,3.8 L116.3,26.3 C117,28.1 118.2,29.1 119.6,29.6 Z M106.5,17.5 L102.6,6.5 L98.7,17.5 L106.5,17.5 Z"}
                id={"Shape"}
              />
              <path
                d={"M146.8,21.8 L144.6,29.7 L120.4,29.7 L120.4,29.6 C121.8,29 122.6,28 122.6,26.1 L122.6,4 C122.6,2.2 121.8,1.1 120.4,0.5 L120.4,0.4 L144,0.4 L146.1,8.4 L146,8.5 C144,6.7 142.2,6.1 139.4,6.1 L129,6.1 L129,11.9 L137.5,11.9 C139.5,11.9 140.6,11 141.2,9.5 L141.3,9.5 L141.3,20 L141.2,20 C140.6,18.5 139.4,17.6 137.5,17.6 L129,17.6 L129,24 L140,24 C142.8,24 144.7,23.4 146.8,21.8 L146.8,21.8 Z"}
                id={"Path"}
              />
              <path
                d={"M174.5,8.4 L174.5,8.4 C172.4,6.7 170.5,6.2 167.8,6.2 L164,6.2 L164,26.2 C164,28 164.8,29.1 166.2,29.7 L166.2,29.8 L155.4,29.8 L155.4,29.7 C156.8,29.1 157.6,28.1 157.6,26.2 L157.6,6.2 L153.9,6.2 C151.1,6.2 149.3,6.7 147.2,8.5 L147.1,8.4 L149.4,0.5 L172.2,0.5 L174.5,8.4 Z"}
                id={"Path"}
              />
              <path
                d={"M177.5,17.8 L177.5,4.1 C177.5,2.3 176.7,1.2 175.3,0.6 L175.3,0.5 L186.1,0.5 L186.1,0.6 C184.7,1.2 183.9,2.2 183.9,4.1 L183.9,17.4 C183.9,21.6 186.8,24.3 190.4,24.3 C194.1,24.3 197,21.6 197,17.4 L197,4.1 C197,2.3 196.2,1.2 194.8,0.6 L194.8,0.5 L205.6,0.5 L205.6,0.6 C204.2,1.2 203.4,2.2 203.4,4.1 L203.4,17.8 C203.4,25.1 197.8,30.3 190.5,30.3 C183.1,30.3 177.5,25.1 177.5,17.8 Z"}
                id={"Path"}
              />
              <path
                d={"M212.4,28.8 C211.6,28.8 210.9,29.1 210.4,29.8 L207.6,18 L207.8,17.9 C211.3,22.1 216.5,24.4 221.2,24.4 C224.7,24.4 227.1,23.4 227.1,21.4 C227.1,19.4 224.6,18.7 219.3,17.8 C214.5,16.9 208.4,15.2 208.4,9 C208.4,3.5 214.1,-2.84217094e-14 221.1,-2.84217094e-14 C225.4,-2.84217094e-14 227.4,1.1 228.8,1.1 C229.6,1.1 230.1,0.9 230.6,0.2 L232.6,11 L232.4,11 C229.8,7.5 225.4,5.6 221,5.6 C217.4,5.6 214.9,6.8 214.9,8.7 C214.9,10.8 218.2,11.3 221.3,12 C227.1,13 233.7,14.8 233.7,21.1 C233.7,26.6 228.4,30.1 221.6,30.1 C217,30.2 213.8,28.8 212.4,28.8 Z"}
                id={"Path"}
              />
            </g>
            {withSlogan && (
              <g transform={"translate(31.000000, 39.000000)"}>
                <polygon
                  id={"Path"}
                  points={"2.6 1.4 0.4 1.4 0.4 0.6 5.8 0.6 5.8 1.4 3.6 1.4 3.6 7.9 2.6 7.9"}
                />
                <polygon
                  id={"Path"}
                  points={"8.5 0.6 8.5 3.7 12 3.7 12 0.6 12.9 0.6 12.9 7.9 12 7.9 12 4.5 8.5 4.5 8.5 7.9 7.6 7.9 7.6 0.6"}
                />
                <polygon
                  id={"Path"}
                  points={"19.4 4.5 16.6 4.5 16.6 7.1 19.8 7.1 19.8 7.9 15.7 7.9 15.7 0.6 19.7 0.6 19.7 1.4 16.7 1.4 16.7 3.7 19.5 3.7 19.5 4.5"}
                />
                <polygon
                  id={"Path"}
                  points={"25.2 0.6 29.1 0.6 29.1 1.4 26.1 1.4 26.1 3.8 28.9 3.8 28.9 4.6 26.1 4.6 26.1 7.9 25.2 7.9"}
                />
                <path
                  d={"M31.5,0.7 C32,0.6 32.7,0.5 33.3,0.5 C34.3,0.5 35,0.7 35.4,1.1 C35.8,1.4 36,1.9 36,2.5 C36,3.5 35.4,4.1 34.6,4.4 L34.6,4.4 C35.2,4.6 35.5,5.1 35.7,5.9 C35.9,6.9 36.1,7.6 36.3,7.9 L35.3,7.9 C35.2,7.7 35,7.1 34.8,6.1 C34.6,5.1 34.2,4.7 33.3,4.7 L32.4,4.7 L32.4,7.9 L31.5,7.9 L31.5,0.7 L31.5,0.7 Z M32.4,4 L33.4,4 C34.4,4 35.1,3.5 35.1,2.6 C35.1,1.6 34.4,1.2 33.4,1.2 C32.9,1.2 32.6,1.2 32.5,1.3 L32.5,4 L32.4,4 Z"}
                  id={"Shape"}
                />
                <polygon
                  id={"Path"}
                  points={"39.3 0.6 39.3 7.9 38.4 7.9 38.4 0.6"}
                />
                <polygon
                  id={"Path"}
                  points={"45.7 4.5 42.9 4.5 42.9 7.1 46.1 7.1 46.1 7.9 42 7.9 42 0.6 46 0.6 46 1.4 43 1.4 43 3.7 45.8 3.7 45.8 4.5"}
                />
                <path
                  d={"M48.2,7.9 L48.2,0.6 L49.2,0.6 L51.5,4.3 C52,5.2 52.5,5.9 52.8,6.7 L52.8,6.7 C52.7,5.7 52.7,4.8 52.7,3.7 L52.7,0.6 L53.6,0.6 L53.6,7.9 L52.6,7.9 L50.3,4.2 C49.8,3.4 49.3,2.5 48.9,1.8 L48.9,1.8 C49,2.7 49,3.6 49,4.8 L49,7.9 L48.2,7.9 Z"}
                  id={"Path"}
                />
                <path
                  d={"M56.3,0.7 C56.9,0.6 57.6,0.5 58.3,0.5 C59.7,0.5 60.6,0.8 61.3,1.4 C62,2 62.3,2.8 62.3,4 C62.3,5.2 61.9,6.2 61.2,6.8 C60.5,7.5 59.4,7.9 58,7.9 C57.3,7.9 56.8,7.9 56.3,7.8 L56.3,0.7 L56.3,0.7 Z M57.3,7.2 C57.5,7.2 57.9,7.2 58.3,7.2 C60.3,7.2 61.4,6.1 61.4,4.1 C61.4,2.4 60.4,1.3 58.4,1.3 C57.9,1.3 57.5,1.3 57.3,1.4 L57.3,7.2 Z"}
                  id={"Shape"}
                />
                <polygon
                  id={"Path"}
                  points={"64.5 0.6 65.4 0.6 65.4 7.1 68.5 7.1 68.5 7.9 64.4 7.9 64.4 0.6"}
                />
                <path
                  d={"M71.3,7.9 L71.3,4.8 L69,0.6 L70.1,0.6 L71.1,2.6 C71.4,3.2 71.6,3.6 71.8,4.1 L71.8,4.1 C72,3.6 72.3,3.1 72.5,2.6 L73.6,0.6 L74.7,0.6 L72.3,4.8 L72.3,7.9 L71.3,7.9 Z"}
                  id={"Path"}
                />
                <path
                  d={"M79.8,0.7 C80.4,0.6 81.1,0.5 81.8,0.5 C83.2,0.5 84.1,0.8 84.8,1.4 C85.5,2 85.8,2.8 85.8,4 C85.8,5.2 85.4,6.2 84.7,6.8 C84,7.5 82.9,7.9 81.5,7.9 C80.8,7.9 80.3,7.9 79.8,7.8 L79.8,0.7 L79.8,0.7 Z M80.8,7.2 C81,7.2 81.4,7.2 81.8,7.2 C83.8,7.2 84.9,6.1 84.9,4.1 C84.9,2.4 83.9,1.3 81.9,1.3 C81.4,1.3 81,1.3 80.8,1.4 L80.8,7.2 Z"}
                  id={"Shape"}
                />
                <polygon
                  id={"Path"}
                  points={"91.8 4.5 89 4.5 89 7.1 92.2 7.1 92.2 7.9 88 7.9 88 0.6 92 0.6 92 1.4 89 1.4 89 3.7 91.8 3.7"}
                />
                <path
                  d={"M94.4,0.7 C94.8,0.6 95.5,0.5 96.1,0.5 C97,0.5 97.7,0.7 98.1,1 C98.5,1.3 98.7,1.7 98.7,2.3 C98.7,3 98.2,3.6 97.5,3.9 L97.5,3.9 C98.2,4.1 99,4.6 99,5.7 C99,6.3 98.8,6.8 98.4,7.2 C97.9,7.7 97.1,7.9 95.9,7.9 C95.2,7.9 94.8,7.9 94.4,7.8 L94.4,0.7 Z M95.3,3.7 L96.2,3.7 C97.2,3.7 97.8,3.2 97.8,2.5 C97.8,1.7 97.2,1.3 96.2,1.3 C95.8,1.3 95.5,1.3 95.4,1.4 L95.4,3.7 L95.3,3.7 Z M95.3,7.2 C95.5,7.2 95.8,7.2 96.1,7.2 C97.1,7.2 98,6.8 98,5.8 C98,4.8 97.1,4.4 96.1,4.4 L95.3,4.4 L95.3,7.2 Z"}
                  id={"Shape"}
                />
                <polygon
                  id={"Path"}
                  points={"102.5 1.4 100.3 1.4 100.3 0.6 105.7 0.6 105.7 1.4 103.5 1.4 103.5 7.9 102.6 7.9 102.6 1.4"}
                />
                <path
                  d={"M115.9,7.7 C115.6,7.9 114.9,8 114,8 C111.9,8 110.4,6.7 110.4,4.3 C110.4,2 111.9,0.5 114.2,0.5 C115.1,0.5 115.7,0.7 115.9,0.8 L115.7,1.6 C115.3,1.4 114.8,1.3 114.2,1.3 C112.5,1.3 111.3,2.4 111.3,4.3 C111.3,6.1 112.3,7.2 114.1,7.2 C114.7,7.2 115.3,7.1 115.6,6.9 L115.9,7.7 Z"}
                  id={"Path"}
                />
                <path
                  d={"M124.1,4.2 C124.1,6.7 122.6,8 120.7,8 C118.8,8 117.4,6.5 117.4,4.3 C117.4,2 118.8,0.5 120.8,0.5 C122.8,0.5 124.1,2 124.1,4.2 Z M118.4,4.3 C118.4,5.9 119.2,7.3 120.7,7.3 C122.2,7.3 123,5.9 123,4.3 C123,2.8 122.2,1.3 120.7,1.3 C119.2,1.2 118.4,2.7 118.4,4.3 Z"}
                  id={"Shape"}
                />
                <polygon
                  id={"Path"}
                  points={"126.3 0.6 127.2 0.6 127.2 7.1 130.3 7.1 130.3 7.9 126.2 7.9 126.2 0.6"}
                />
                <polygon
                  id={"Path"}
                  points={"132.4 0.6 133.3 0.6 133.3 7.1 136.4 7.1 136.4 7.9 132.3 7.9 132.3 0.6"}
                />
                <polygon
                  id={"Path"}
                  points={"142.2 4.5 139.4 4.5 139.4 7.1 142.6 7.1 142.6 7.9 138.5 7.9 138.5 0.6 142.5 0.6 142.5 1.4 139.5 1.4 139.5 3.7 142.3 3.7 142.3 4.5"}
                />
                <path
                  d={"M149.9,7.7 C149.6,7.9 148.9,8 148,8 C145.9,8 144.4,6.7 144.4,4.3 C144.4,2 145.9,0.5 148.2,0.5 C149.1,0.5 149.7,0.7 149.9,0.8 L149.7,1.6 C149.3,1.4 148.8,1.3 148.2,1.3 C146.5,1.3 145.3,2.4 145.3,4.3 C145.3,6.1 146.3,7.2 148.1,7.2 C148.7,7.2 149.3,7.1 149.6,6.9 L149.9,7.7 Z"}
                  id={"Path"}
                />
                <polygon
                  id={"Path"}
                  points={"153.7 1.4 151.5 1.4 151.5 0.6 156.9 0.6 156.9 1.4 154.7 1.4 154.7 7.9 153.8 7.9 153.8 1.4"}
                />
                <path
                  d={"M164.7,4.2 C164.7,6.7 163.2,8 161.3,8 C159.4,8 158,6.5 158,4.3 C158,2 159.4,0.5 161.4,0.5 C163.4,0.5 164.7,2 164.7,4.2 Z M159,4.3 C159,5.9 159.8,7.3 161.3,7.3 C162.8,7.3 163.6,5.9 163.6,4.3 C163.6,2.8 162.8,1.3 161.3,1.3 C159.8,1.2 159,2.7 159,4.3 Z"}
                  id={"Shape"}
                />
                <path
                  d={"M166.9,0.7 C167.4,0.6 168.1,0.5 168.7,0.5 C169.7,0.5 170.4,0.7 170.8,1.1 C171.2,1.4 171.4,1.9 171.4,2.5 C171.4,3.5 170.8,4.1 170,4.4 L170,4.4 C170.6,4.6 170.9,5.1 171.1,5.9 C171.3,6.9 171.5,7.6 171.7,7.9 L170.7,7.9 C170.6,7.7 170.4,7.1 170.2,6.1 C170,5.1 169.6,4.7 168.7,4.7 L167.8,4.7 L167.8,7.9 L166.9,7.9 L166.9,0.7 L166.9,0.7 Z M167.8,4 L168.8,4 C169.8,4 170.5,3.5 170.5,2.6 C170.5,1.6 169.8,1.2 168.8,1.2 C168.3,1.2 168,1.2 167.9,1.3 L167.9,4 L167.8,4 Z"}
                  id={"Shape"}
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { styled } from '@mui/material/styles'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import Tooltip from '@mui/material/Tooltip'
import christmasHatImage from '@straetus/react/assets/christmas-hat.png'

const StyledRoot = styled('div')({
  display: 'inline-block'
})

const StyledSanta = styled(({ className }: { className?: string }) => (
  <img
    alt={'Merry Christmas and a happy new year!'}
    className={className}
    src={christmasHatImage} />
))({
  position: 'absolute',
  transform: 'rotate(-18deg) scaleX(-1)',
  width: 30,
  left: 12,
  top: 5
})

export default function StraetusIcon(props: SvgIconProps) {
  const isDecember = React.useMemo(() => new Date().getMonth() === 11, [])
  const isSpecial = true

  return (
    <Tooltip title={isDecember && (
      <FormattedMessage
        defaultMessage={'Merry Christmas and a happy new year!'}
        id={'specials.christmas-happy-new-year'} />
    )}>
      <StyledRoot>
        {isDecember && (
          <StyledSanta />
        )}

        <SvgIcon
          height={'37px'}
          viewBox={'0 0 35 37'}
          width={'35px'}  {...props}>
          <g fill={'inherit'}
             fillRule={'evenodd'}
             id={'Straetus-Icon'}
             stroke={'none'}
             strokeWidth={'1'}>

            {isSpecial && (
              <g fill={'inherit'} transform={'translate(-35, -23) scale(0.15)'}>
                <path
                  d={'m315.52,312.18c9.69,0,17.63,2.34,23.02,6.36-3.34-10.15-5.13-20.85-5.26-31.78-3.87-.62-7.93-.94-12.16-.94-8.79,0-17.37,2-23.96,4.39l2.4-24.96h35.66c1.73-8.89,4.58-17.47,8.47-25.56h-69.1l-8.39,87.66,1,.6c11.78-9.19,31.15-15.77,48.32-15.77Z'} />
                <path
                  d={'m362.28,358.2c-6.41-6.75-11.84-14.23-16.2-22.23-1.6,10.97-13.31,18.54-32.75,18.54-17.37,0-40.14-7.99-53.12-20.37l-.8.4,14.18,47.52c2.8-3.19,5.79-4.19,9.19-4.19,6.99,0,17.37,4.19,32.95,4.19,22.13,0,40.04-7.48,50.66-19.73-2.39-2.3-3.86-3.87-4.1-4.13Z'} />
                <path
                  d={'m344.43,285.49c0-2.75.12-5.48.35-8.16,4.14-48.36,44.68-86.3,94.1-86.3,14.93,0,29.04,3.47,41.59,9.64l-14.31,16.04s-2.95,3.46-8.34,2.52c-5.16-.9-10.46-1.47-15.89-1.47-37.64,0-69.6,24.27-81.1,58.01-2.95,8.68-4.56,17.98-4.56,27.65,0,17.4,5.19,33.59,14.11,47.1-16.09-16.94-25.96-39.83-25.96-65.04m113.27-59.3l-5.72,6.42s-2.93,2.81-7.02,2.62c-1-.04-2.01-.06-3.02-.06-25.47,0-47.68,13.95-59.4,34.63-2.9,4.82-5.7,4.43-5.7,4.43l-8.43.81c11.39-29.5,40.02-50.43,73.53-50.43,5.4,0,10.67.55,15.76,1.58m-10.62,140.52c-1.84-.87-3.64-1.79-5.4-2.79,0,0-2.19,1.07-6.07,2.69-10.66,4.48-34.04,13.14-59.11,14.85l.08-.07c1.13-1.18,14.34-16.02,3.55-63.11-1.14-4.78-1.75-9.75-1.75-14.87,0-35.08,28.43-63.51,63.5-63.51,16.95,0,32.35,6.64,43.73,17.46,3.26,3,6.18,6.43,8.71,10.23,4.83,7.05,8.27,15.13,9.94,23.83,6.53,22.94,11.3,31.76,11.3,31.76-6.66,0-9.95,2.03-9.95,2.02-.11-.78-.22-1.55-.34-2.3-.76-5.65-3.57-21.14-12.27-27.84-.73-.76-6.77-5.32-14.39-5.05-20.09.72-24.52,11.26-24.52,11.26,0,0,13.35,15.43,36.54,3.48l32.34,72.87s-43.35,4.48-75.88-10.92'} />
                <path
                  d={'m203.3,381.07c7.99-2.8,12.78-7.79,12.78-16.57v-78.27h-25.56v-4.59c22.56-8.19,38.94-18.97,49.12-41.33h6.99v124.2c0,8.79,4.79,13.78,12.78,16.57v.4c-19.03-2.13-37.77-2.34-56.11,0v-.4Z'} />
              </g>
            )}

            {!isSpecial && (
              <g id={'Straetus-Logo-01'}>
                <path
                  d={'M35,36.0918478 C29.8955987,36.459806 25.1549502,36.1979294 20.6633933,34.2912012 C19.3902583,33.7474842 18.3820303,33.876661 17.1326184,34.2720204 C13.6730125,35.3676747 10.1461915,36.2640836 6.51261684,37 C8.4816268,32.8041019 7.76202878,28.7679134 6.93567721,24.755603 C5.47275816,17.6982425 9.40287042,11.3447 16.2706823,9.75543368 C22.9724331,8.2096177 29.4132308,12.2649871 31.2359488,19.0996153 C31.8013473,21.2169409 32.5881605,23.2837701 33.3275277,25.5427987 C32.6909602,25.6950708 32.1334694,25.8320765 31.4534098,25.9964833 C30.6586889,23.3150857 30.4807663,20.1835263 27.0646526,19.4710966 C25.087735,19.0600794 23.1582633,19.4910602 21.6676674,21.2913154 C23.7711078,23.3033423 26.0485169,23.0140645 28.590833,21.8863117 C30.7338117,26.6306241 32.8214368,31.25398 35,36.0918478'}
                  id={'Fill-1'} />

                {!isDecember && (
                  <>
                    <path
                      d={'M26.5072014,2.12636794 C24.8979904,4.2636572 23.5655479,6.01733043 20.0782652,5.60239882 C8.15349801,4.18928266 -0.979861474,17.0991361 3.86754057,28.1457117 C4.14035521,28.7681091 4.39340067,29.4022499 4.8006457,30.3691188 C-1.27639912,24.6696808 -1.5768906,13.9245178 3.8596329,7.1133762 C9.34360243,0.239603435 19.9438348,-2.12863832 26.5072014,2.12636794'}
                      id={'Fill-3'} />
                    <path
                      d={'M22.1776727,7.57524206 C21.1299063,8.00583147 20.0900476,8.77697796 19.0304198,8.812208 C14.1790639,8.96095707 10.4861818,11.0160429 7.90432731,14.9970377 C7.14914478,16.1635436 6.23976266,16.5041007 4.80452046,16.2418326 C7.0502989,9.44243432 16.1283047,4.65897742 22.1776727,7.57524206'}
                      id={'Fill-6'} />
                  </>
                )}
              </g>
            )}
          </g>
        </SvgIcon>
      </StyledRoot>
    </Tooltip>
  )
}
